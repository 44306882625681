<template>
  <div
    id="video-not-found"
    class="bg-video-not-found"
  >
    <div class="d-flex align-center justify-center logo-video-not-found">
      <v-img
        :src="appLogo"
        max-height="40px"
        max-width="40px"
        alt="logo"
        contain
        class="me-3 "
      ></v-img>
      <span
        class="font-weight-bold text-xl"
        style="color: white !important; font-family: 'Orbitron';"
      >
        {{ appName }}
      </span>
    </div>

    <div class="header-video-app d-flex justify-center">
      <div class="d-flex align-center">
        <span class="white--text text-sm">
          <strong>Video Test</strong>
        </span>
      </div>
    </div>

    <div
      class="auth-wrapper auth-v1"
    >
      <div
        class="auth-inner card-width"
      >
        <v-row
          class="d-flex justify-center"
          style="margin-bottom: 100px !important;"
        >
          <v-col
            cols="12"
            md="12"
            sm="10"
            class="align-self-start pa-0"
          >
            <video-player
              id="refVideoPlayer"
              ref="refVideoPlayer"
              class="video-player-box"
              :options="playerOptions"
              :playsinline="true"
              custom-event-name="customstatechangedeventname"
            />
            <!--
              @timeupdate="onPlayerTimeupdate($event)"
              @canplaythrough="onPlayerCanplaythrough($event)"
              @ready="playerReadied"
              @loadeddata="onPlayerLoadeddata($event)"
              @loadedmetadata="onPlayerLoadedmetadata($event)"
            -->
          </v-col>
        </v-row>

        <v-row
          class="d-flex justify-center"
          style="margin-bottom: 100px !important;"
        >
          <v-col
            cols="12"
            md="12"
            sm="10"
            class="align-self-start pa-0"
          >
            <video-js
              id="myVideo1"
              ref="myVideo1"
              class="vjs-default-skin"
              controls
              preload="auto"
              width="640"
              height="268"
            >
              <!-- src="https://d2zihajmogu5jn.cloudfront.net/bipbop-advanced/bipbop_16x9_variant.m3u8" -->
              <!-- type="application/x-mpegURL" -->
              <source
                src="https://dash.akamaized.net/dash264/TestCasesVP9/vp9-hd/sintel-vp9-hd.mpd"
                type="application/dash+xml"
              >
            </video-js>
          </v-col>
        </v-row>
      </div>
    </div>
  </div>
</template>

<script src="https://unpkg.com/video.js/dist/video.js"></script>
<script src="https://unpkg.com/@videojs/http-streaming/dist/videojs-http-streaming.js"></script>
<script>
/* eslint-disable import/no-cycle */
/* eslint-disable object-curly-newline */
import { ref, onMounted } from '@vue/composition-api'
import { mdiDeleteForever } from '@mdi/js'
import themeConfig from '@themeConfig'
import { useUtils } from '@core/libs/i18n'
import videojs from 'video.js'

export default {
  setup() {
    const myVideo1 = ref(null)

    const { t } = useUtils()

    const refVideoPlayer = ref(null)
    const playerOptions = ref({
      controls: false,
      responsive: true,
      breakpoints: true,
      fill: true,
      autoplay: true,
      muted: true,
      loop: true,
      language: 'en',
      playbackRates: [0.7, 1.0, 1.5, 2.0],
      sources: [],
      poster: themeConfig.app.logo,
      notSupportedMessage: 'Este video no está disponible temporalmente, intente nuevamente más tarde.',
      usingPlugin: true,
      fluid: true,
      aspectRatio: '16:9',
    })

    onMounted(async () => {
      const player = videojs('myVideo1')
      player.play()

      playerOptions.value.sources.push({
        type: 'video/mp4',
        src: 'https://static.smartisanos.cn/common/video/smartisan-tnt-jianguo.mp4',
      })
    })

    return {
      // data
      refVideoPlayer,
      playerOptions,
      myVideo1,

      // themeConfig
      appName: themeConfig.app.name,
      appLogo: themeConfig.app.logo,

      // i18n
      t,

      icons: {
        mdiDeleteForever,
      },
    }
  },
}
</script>

<style lang="scss">
@import '@core/preset/preset/pages/auth.scss';
</style>
<style scope>
.img-h {
  width: 100% !important;
  height: 56vh !important;
}

.card-width {
  width: 50rem;
}

/* Extra small devices (phones, 600px and down) */
@media only screen and (max-width: 600px) {
  .img-h {
    width: 100% !important;
    height: 26vh !important;
  }

  .card-width {
    width: 20rem !important;
  }
}
/* Small devices (portrait tablets and large phones, 600px and up) */
@media only screen and (min-width: 600px) {
  .img-h {
    width: 100% !important;
    height: 26vh !important;
  }

  .card-width {
    width: 20rem !important;
  }
}
/* Medium devices (landscape tablets, 768px and up) */
@media only screen and (min-width: 768px) {
  .img-h {
    width: 100% !important;
    height: 50vh !important;
  }

  .card-width {
    width: 50rem !important;
  }
}
/* Large devices (laptops/desktops, 992px and up) */
@media only screen and (min-width: 992px) {
  .img-h {
    width: 100% !important;
    height: 64vh !important;
  }

  .card-width {
    width: 50rem !important;
  }
}
/* Extra large devices (large laptops and desktops, 1200px and up) */
@media only screen and (min-width: 1200px) {
  .img-h {
    width: 100% !important;
    height: 64vh !important;
  }

  .card-width {
    width: 50rem !important;
  }
}

#video-not-found {
  font-family: 'Open Sans' !important;
}

.bg-video-not-found {
  background-color: black;
}

.header-video-not-found {
  background-color: #ff3232;
  min-height: 6vh;
}

.header-video-app {
  background-color: #ff3232;
  min-height: 6vh;
}

.red {
  color: #ff3232;
}

.logo-video-not-found {
  background-color: #1a1a1a;
  min-height: 14vh;
}

.btn-video-not-found {
  min-height: 12vh;
}

.auth-wrapper {
  min-height: calc(var(--vh, 1vh) * 80);
}

.img-h .v-responsive__content {
  width: 100% !important;
}

.img-h .v-responsive__sizer {
  padding: 0 !important;
}

.delete-icon {
  background-color: #212121;
}
</style>
